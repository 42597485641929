import { useFormspark } from "@formspark/use-formspark";
import "./App.css";
import { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// function Header() {
//   return (
//     <header className="sticky top-0 z-50 w-full flex-col items-center transition duration-300 block -mb-20">
//       <div className="min-h-[70px] backdrop-blur bg-transparent relative z-50 flex w-full content-center items-center justify-between border-b border-[#EADDD7] px-5 py-1 transition-all duration-300 xl:grid xl:grid-cols-3">
//         <a className="max-w-[150px] md:max-w-[196px]" href="/">
//           <img
//             alt="Emerald Logo"
//             fetchpriority="high"
//             width="150px"
//             height="auto"
//             decoding="async"
//             data-nimg="1"
//             style={{ color: "transparent" }}
//             className="my-2"
//             src="/logo.svg"
//           />
//         </a>
//         <nav className="hidden items-center justify-center gap-6 lg:flex xl:w-full">
//           &nbsp;
//         </nav>
//         <div className="overflow-x-hidden">
//           <div className="hidden w-full items-center justify-end gap-6 transition duration-300 lg:flex translate-x-32">
//             <div className="flex items-center gap-6">
//               <div className="flex items-center gap-2">
//                 <a
//                   href="#contact"
//                   className="inline-flex font-semibold text-lg border-[2px] justify-center gap-2 items-center whitespace-nowrap ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 tracking-[-0.5px] font-poppins rounded-full border-theme_bronze_7 bg-[#E7FAF9] text-theme_orange_dark_2 hover:bg-theme-green-light h-12 px-5 py-3"
//                 >
//                   Get a Demo
//                 </a>
//                 <a
//                   href="#contact"
//                   className="inline-flex text-lg border-[2px] border-transparent justify-center gap-2 items-center whitespace-nowrap rounded-full dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 tracking-[-0.5px] font-poppins bg-theme-green font-semibold text-theme_white ring-offset-theme-green transition hover:border-theme-green hover:bg-[#3E9284] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-black focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-12 px-5 py-3 pointer-events-none opacity-0 duration-300"
//                 >
//                   Start Now
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// }

// const AnimatedText = () => {
//   const words = [
//     "ChatGPT",
//     "Gemini API",
//     "Llama 2",
//     "HuggingFace",
//     "LangChain",
//     "Claude 3",
//   ];
//   const [index, setIndex] = useState(0);

//   const maxLengthWord = words.reduce((a, b) => (a.length > b.length ? a : b));

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setIndex((prevIndex) => (prevIndex + 1) % words.length);
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, [words.length]);

//   return (
//     <h1 className="max-w-[370px] font-poppins text-4xl font-semibold leading-tight tracking-[-2.5px] md:max-w-full md:text-[4rem] md:leading-[1]">
//       <span style={{ lineHeight: "1.7" }}>
//         Fearlessly use{" "}
//         <TransitionGroup component="span" className="relative inline-block">
//           <span
//             className="backdrop-blur-sm bg-white/30"
//             style={{
//               color: "transparent",
//               lineHeight: "1.5",
//               borderRadius: "15px",
//               padding: "8px",
//               paddingLeft: "16px",
//               paddingRight: "16px",
//               marginLeft: "8px",
//               border: "1.5px solid rgb(55, 206, 106)",
//             }}
//           >
//             {maxLengthWord}
//           </span>

//           <CSSTransition key={words[index]} timeout={1000} classNames="fade">
//             <span
//               className="absolute top-0 left-0 w-full maintext"
//               style={{
//                 lineHeight: "1.7",
//                 paddingLeft: "16px",
//                 paddingRight: "16px",
//               }}
//             >
//               {words[index]}
//             </span>
//           </CSSTransition>
//         </TransitionGroup>
//       </span>
//     </h1>
//   );
// };

function FirstSection() {
  return (
    <section
      class="flex flex-col items-center pb-12 pt-24 text-center"
      style={{
        // minHeight: "100vh",
        backgroundColor: "#EDFDF2",
        backgroundImage: "url(/bg-tile.svg)",
        backgroundRepeat: "repeat",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
      }}
    >
      <div style={{ flex: 1 }}>&nbsp;</div>
      <img
        src="/logo.svg"
        alt="Emerald Logo"
        className="my-2"
        style={{ height: "100px", width: "200px" }}
      />
      <span style={{ fontSize: "larger" }}>
        Emerald transforms the way you think about security.
      </span>
      <br />
      <br />
      <span
        style={{
          fontSize: "larger",
          fontWeight: "bold",
          display: "inline-block",
        }}
      >
        Want to learn more?
      </span>
      <a href="mailto:yotam@emerald-security.com">Contact Us.</a>

      <div style={{ flex: 3 }}>&nbsp;</div>
      <img
        alt=""
        loading="lazy"
        width="1445"
        height="1105"
        decoding="async"
        data-nimg="1"
        class="pointer-events-none absolute bottom-auto left-auto right-0 top-0 z-10 hidden w-full md:block"
        style={{ color: "transparent", opacity: 0.5, zIndex: -1 }}
        src="/bg.svg"
      />
    </section>
  );
}

function App() {
  return (
    <div className="App">
      <FirstSection />
    </div>
  );
}

export default App;
